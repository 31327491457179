.cards-container {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 20px;
    overflow: hidden;
}

.inner-cards-container {
    padding: 30px;

}

.plan {
    color: rgb(151, 151, 151);
}

// .first-edu-container {
//     background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

// }

.second-edu-container {
    background-image: linear-gradient( 112.4deg,  rgba(169,28,115,1) 21.6%, rgba(219,112,54,1) 92.2% );
}
.svg{
    transform: rotate(180deg);
}

.summer-camp-container{
    box-shadow: #74104d 0px 2px 5px -1px, #74104d 0px 1px 3px -1px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-image: url('../../../../public/images/backgrounds/laptop.jpg');
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.708);
    background-blend-mode: multiply;
}