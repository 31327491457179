.main-cont{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
}

.text-container{
    margin-top: auto;
}

.mock-image{
    margin: auto;
}

/* Portfolio.scss */
.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed */
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #E57C04; /* Loading spinner color */
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .d-flex{
    display: flex;
  }
  .d-none{
    display: none;
  }
  .d-block{
    display: block;
  }