@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind *;

@font-face {
    font-family: 'TGA';
    src: url('./Fonts/tex-gyre-adventor/texgyreadventor-regular.otf') format('opentype');
}

/* @font-face {
    font-family: 'TGA';
    src: url('./Fonts/tex-gyre-adventor/texgyreadventor-bold.otf') format('opentype');
} */

body {
    margin: 0;
    font-family: 'TGA';
    /* background-color: black; */
    overflow-x: hidden;
}
h1{
    font-weight: bold;
}