//align
.center{
    justify-content: center;
    align-items: center;
}



//colors
.color-orange{
    color: #E57C04;
}
.bg-color-orange{
    background-color: #E57C04;
}
.bg-color-yellow{
    background-color: #F3B700;
}
.color-yellow{
    color: #F3B700;
}
.bg-color-blue{
    background-color: #0E1428;
}
.color-blue{
    color: #0E1428;
}
.color-pink{
    color: #74104d
}
.bg-color-pink{
    background-color: #74104d
}
//fonts
.bold{
    font-weight: bold;
}
.why-cont{
    // background-color: #fdda70;
}


.bg-yellow{
    filter: drop-shadow(10px 10px 2px gray);

}