.line {
    width: 100vw;
  }
  
  .a-left,
  .a-right {
    width: 50vw;
    overflow: hidden;
    display: inline-block;
  }
  
  .a-left {
    // color:#74104d;
    transform: skew(0deg, -15deg);
  }
  
  .a-right {
    color: black;
    transform: skew(0deg, 15deg);
  }
  
  .a-left .content {
    width: 100vw;
    text-align: center;
  }
  
  .a-right .content {
    width: 100vw;
    text-align: center;
    transform: translate(-50vw);
  }
  
  .content {
    padding: 10px;
  }
  
  span {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 8vw;
    text-transform: uppercase;
    line-height: .8;
    transition: ease-out .6s;
  }
  
  .spanSlow,
  .spanFast {
    padding: 10px;
  }