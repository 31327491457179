.contact-inputs{
    height: 50px;
    background-color: transparent;
    padding: 10px;
    border-bottom: 1px solid black;
}
.contact-inputs::placeholder{
    color: black;
    font-size: 20px;
}
#message{
    height: 200px;
}
.contact-inputs:focus{
    border: 2px solid #F3B700;
    outline-offset: 0px;
    outline: none;
}
.even-containers{
    height: 30%;
}