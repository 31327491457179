$total-lines: 3;


/*
** Leave these unless you want
** to open a whole can of worms
*/
$left-offset: 50px;
$clip-height: 90px;
$line-height: $clip-height - 10px;


.header-title {
  line-height: 340px;
}


.sticky {
  position: -webkit-sticky !important;
  /* for browser compatibility */
  position: sticky !important;
  top: 0.1px;

}

.bg-home {
  filter: drop-shadow(-5px 10px 2px gray);
  transition: 0.5s;
  // position: relative;
}

// $h1:  rgba(45,45,45,1);




.miket {
  filter: drop-shadow(-10px 5px 2px rgb(176, 175, 175));

}



//text-sphere
.text-sphere {
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.tagcloud {
  display: inline-block;
  top: 0;
  left: 0;
  letter-spacing: 0.0625em;
  font-size: 2vw;
}


.tagcloud--item {
  color: #E57C04;
  text-transform: uppercase;
}

.tagcloud--item:hover {
  color: black;
}

.fire-dec {
  position: relative;
  top: -100px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: drop-shadow(5px 5px 3px gray);
}



.about-text {
  background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #000000, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  // background-color: black;
  color: transparent;
  font-size: 13vw;
  animation: reveal 1500ms ease-in-out forwards 200ms,
    //  glow 2500ms linear infinite 2000ms;
}

@keyframes reveal {
  80% {
    letter-spacing: 12px;
  }

  100% {
    background-size: 300% 300%;
    background-color: black;
  }
}

@keyframes glow {
  40% {
    text-shadow: 0 0 8px #000000;
  }
}





//aniamtions intro
@keyframes intro {
  from {
    opacity: 0;
    width: 40%;
  }

  to {
    opacity: 1;
    width: 45%;

  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.header-title {
  animation-name: opacity;
  animation-duration: 2s;
  animation-iteration-count: 1;
  line-height: 50px;
}

.title-image {
  animation-name: intro;
  animation-duration: 1s;
  animation-iteration-count: 1;
  // width: 45%;
}

@keyframes meniher {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(-0px);

  }
}

@keyframes vone {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(-0px);

  }
}

.meniher {
  padding: 10px 40px;
  // font-size: 20px;
  // animation-name: meniher;
  // animation-duration: 1s;
  // animation-iteration-count: 1;
}

.vone {
  // animation-name: meniher;
  // animation-duration: 1s;
  // animation-iteration-count: 1;
  // font-size: 20px;
  padding: 10px 40px;
  // border: 3px solid black;
}





//about transitions
.about-title {
  opacity: 0;
  transform: translateX(-80px);
}

.about-title-transition {
  opacity: 1;
  transform: translateX(0px);
  transition: 0.5s;
}

.about {
  transition: 0.5s;
}

.about-subtitle {
  opacity: 0;
  transform: translateY(50px);
}

.about-subtitle-transition {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.5s;
  transition-duration: 0.5s;
}

.about-image {
  opacity: 0;

}

.about-image-transition {
  opacity: 1;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.about-content {
  opacity: 0;
  transform: translateY(50px);
}

.about-content-transition {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 1s;
  transition-duration: 0.5s;
}

//why transitions
.why-before {
  opacity: 0;
}

.why-transition {
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.why-text {
  transition-delay: 0.5s;
}

//index transition
.index-image {
  opacity: 0;
  transform: translateY(100px);

}

.index-image-transition {
  opacity: 1;
  transition: 0.5s;
  transform: translateY(0px);
  transition-delay: 0.5s;
}




















//3d animation
@keyframes continuousHover {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -($clip-height), 0); /* Adjust the amount and direction of movement */
  }
}
.Words {
  padding: 200px 0;
  font-weight: 900;
  letter-spacing: -2px;
  text-transform: uppercase;
  // Sort out nasty text fuzz
  transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-font-kerning: normal;
  -webkit-text-size-adjust: 100%;
}

.Words p{
  animation: continuousHover 5s ease-in-out infinite;
}
/*
  ** Apply common styles to each line of text
  */
.Words-line {
  height: $clip-height;
  overflow: hidden;
  position: relative;

  // Change the perspective of each alternating line
  &:nth-child(odd) {
    transform: skew(60deg, -30deg) scaleY(.66667);
  }

  &:nth-child(even) {
    transform: skew(0deg, -30deg) scaleY(1.33333);
  }

  // Loop over the total lines and apply a left offset
  @for $i from 1 through $total-lines+1 {
    &:nth-child(#{$i}) {
      left: $left-offset * $i;
    }
  }
}


/*
  ** Fine-grained text styles
  */
p.threeD {
  height: $clip-height;
  line-height: $line-height;
  padding: 0 10px;
  transition: all .4s ease-in-out;
  transform: translate3d(0, -($clip-height), 0);

  vertical-align: top;
  white-space: nowrap;
  font-size: 6vw;
  // filter: drop-shadow(-10px 5px 3px rgb(235, 234, 234));
}

#color-pink {
  color: #74104d;
}

/*
  ** The hover interaction
  */
.Words:hover {
  p {
    transform: translate3d(0, 0, 0);

  }
}

@media screen and (max-width:768px) {
  $total-lines: 3;

  @keyframes intro {
    from {
      opacity: 0;
      width: 40%;
    }

    to {
      opacity: 1;
      width: 70%;

    }
  }

  /*
** Leave these unless you want
** to open a whole can of worms
*/
  $left-offset: 30px;
  $clip-height: 60px;
  $line-height: $clip-height - 10px;

  .Words {
    padding: 120px 0;
    font-weight: 900;
    letter-spacing: -2px;
    text-transform: uppercase;
    // Sort out nasty text fuzz
    transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -webkit-font-kerning: normal;
    -webkit-text-size-adjust: 100%;
  }


  /*
    ** Apply common styles to each line of text
    */
  .Words-line {
    height: $clip-height;
    overflow: hidden;
    position: relative;

    // Change the perspective of each alternating line
    &:nth-child(odd) {
      transform: skew(60deg, -30deg) scaleY(.66667);
    }

    &:nth-child(even) {
      transform: skew(0deg, -30deg) scaleY(1.33333);
    }

    // Loop over the total lines and apply a left offset
    @for $i from 1 through $total-lines+1 {
      &:nth-child(#{$i}) {
        left: $left-offset * $i;
      }
    }
  }


  /*
    ** Fine-grained text styles
    */
  p.threeD {
    height: $clip-height;
    line-height: $line-height;
    padding: 0 10px;
    transition: all .4s ease-in-out;
    transform: translate3d(0, -($clip-height), 0);

    vertical-align: top;
    white-space: nowrap;
    font-size: 11vw;
    // filter: drop-shadow(-10px 5px 3px rgb(235, 234, 234));
  }

  #color-pink {
    color: #74104d;
  }

  /*
    ** The hover interaction
    */
  .Words:hover {
    p {
      transform: translate3d(0, 0, 0);

    }
  }

}



@media screen and (min-width:530px) and (max-width:999px) {
  $total-lines: 3;

  $left-offset: 50px;
  $clip-height: 90px;
  $line-height: $clip-height - 10px;

  .Words {
    padding: 120px 0;
    font-weight: 900;
    letter-spacing: -2px;
    text-transform: uppercase;
    // Sort out nasty text fuzz
    transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -webkit-font-kerning: normal;
    -webkit-text-size-adjust: 100%;
  }


  /*
    ** Apply common styles to each line of text
    */
  .Words-line {
    height: $clip-height;
    overflow: hidden;
    position: relative;

    // Change the perspective of each alternating line
    &:nth-child(odd) {
      transform: skew(60deg, -30deg) scaleY(.66667);
    }

    &:nth-child(even) {
      transform: skew(0deg, -30deg) scaleY(1.33333);
    }

    // Loop over the total lines and apply a left offset
    @for $i from 1 through $total-lines+1 {
      &:nth-child(#{$i}) {
        left: $left-offset * $i;
      }
    }
  }


  /*
    ** Fine-grained text styles
    */
  p.threeD {
    height: $clip-height;
    line-height: $line-height;
    padding: 0 10px;
    transition: all .4s ease-in-out;
    transform: translate3d(0, -($clip-height), 0);
    font-size: 8vw;
    // filter: drop-shadow(-10px 5px 3px rgb(235, 234, 234));
  }

  #color-pink {
    color: #74104d;
  }

  /*
    ** The hover interaction
    */
  .Words:hover {
    p {
      transform: translate3d(0, 0, 0);

    }
  }

}