//navigation
.brand_title{
    font-size: 20px;
}
.nav-lines{
    height: 1px;
    background-color: black;
    border: 0px solid transparent;
    transition: 0.1s ;
}
#bottom-line{
    transition: 0s;
}

//footer
.footer-container{
    // background-image: linear-gradient(to right, #f83600 0%, #f9d423 100%);
    // background-image: linear-gradient( 109.7deg,  rgba(229,0,57,1) 1.8%, rgba(162,91,182,1) 90.2% );
    // background-image: linear-gradient( 109.7deg,  rgba(202,50,50,1) 5.7%, rgba(252,195,12,1) 92.4% );
    // background-image: linear-gradient( 181.3deg,  rgba(134,15,15,1) 24.9%, rgba(183,10,10,1) 46.9%, rgba(210,70,0,1) 85.1% );
    // background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(253,203,50,1) 0%, rgba(244,56,98,1) 100.2% );
    // background-image: linear-gradient( 112.4deg,  rgba(169,28,115,1) 21.6%, rgba(219,112,54,1) 92.2% );
}
@keyframes slide {
    from{
        transform: translateX(-100vw);
    }to{
        transform: translateX(0vw);
    }
}
.navigation-responsive{
    transition: 0.2s
}